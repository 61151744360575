<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافة جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/system/branches')"
        />
      </template>
    </Toolbar>
    <div class="form-card">
      <div class="mb-3">
        <label for="name" class="form-label required">
          الاسم
          <span>*</span>
        </label>
        <input
          class="form-control"
          id="name"
          v-model="body.name"
          :class="{ 'is-invalid': isInvalid && !body.name }"
          required
        />
      </div>

      <div class="mb-3">
        <label for="phone" class="form-label required">
          الهاتف الثابت
          <span>*</span>
        </label>
        <input
          class="form-control"
          id="phone"
          v-model="body.phone"
          :class="{ 'is-invalid': isInvalid && !body.phone }"
          required
        />
      </div>

      <div class="mb-3">
        <label for="currenciesId" class="form-label required">
          العملة الافتراضيةللفرع
          <span>*</span>
        </label>

        <select
          class="form-select form-control"
          id="currenciesId"
          name="currenciesId"
          v-model.number="body.currenciesId"
          :class="{ 'is-invalid': isInvalid && !body.currenciesId }"
          required
        >
          <option
            v-for="item of currenciesList"
            :key="item.id"
            :value="item.id"
            >{{ item.name }}</option
          >
        </select>
      </div>

      <div class="mb-3 text-center">
        <Button
          label="تعديل"
          icon="pi pi-pencil" v-tooltip="'تعديل'"
          class="p-ml-2 p-button-info"
          @click="update()"
          v-if="id"
          :disabled="disabledAdd"
        />
        <Button
          v-else
          label="اضافة جديد"
          icon="pi pi-plus" v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          @click="save()"
          :disabled="disabledAdd"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        name: null,
        phone: null,
        currenciesId: 1,
      },
      id: null,
      currenciesList: [],
      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    save() {
      if (this.body.name && this.body.phone && this.body.currenciesId) {
        this.disabledAdd = true;
        this.$http.post(`branches`, this.body).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم الاضافة بنجاح',
              life: 3000,
            });
            this.disabledAdd = false;
            this.$router.push('/admin/system/branches');
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
    update() {
      if (this.body.name && this.body.phone && this.body.currenciesId) {
        this.disabledAdd = true;
        this.$http.put(`branches/${this.id}`, this.body).then(
          () => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/system/branches');
          },
          (err) => {
            this.disabledAdd = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {

        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
  },
  created() {
         if (!this.$checkRoles('branchesAdd')) {
      this.$router.push('/admin/');
    }
    this.$http.get(`currencies`).then((res) => {
      this.currenciesList = res.data;
    });
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`branches/${this.id}`).then(
        (res) => {
          this.body = res.data;
          this.body.currenciesId = res.data.currenciesId.id;
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>
